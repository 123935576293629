export default {
  optionalLabel: 'Optional',
  fieldValidationMessage: '{{fieldName}} is required',
  containerSection: {
    expand: 'Expand',
    close: 'Close',
  },
  filter: {
    title: 'Filter',
    placeholder: 'Search options',
    noResult: 'No options found',
    initialMessage: 'Start typing to search',
  },
  sortingLabel: 'Sorted by',
  searchbar: {
    placeholder: 'What are you looking for?',
    suggestions: 'Suggestions...',
  },
  breadcrumbs: {
    back: 'Back',
  },
  shared: {
    address: {
      floor: 'Floor: {{floor}}',
    },
    support: {
      phone: 'NOT SPECIFIED',
    },
    reviews: {
      num: '{{num}} reviews',
    },
  },
  yes: 'Yes',
  no: 'No',
  dropDownNotSelectedOption: '--Select an option--',
  feedbackDialog: {
    contact: {
      title: 'Do you have any questions?',
      text: 'You can reach us by phone under ',
    },
    confirm: 'Confirm',
    abort: 'Abort',
    errors: {
      radioRequired: 'Please select at least one option',
      freeTextRequired: 'Please specify a reason',
    },
  },
  paginationCounterOfLabel: 'of',
  support: {
    operatingHours: 'Mo - Fr 08:00 - 18:00',
    contactCard: {title: "Questions? We're happy to help."},
  },
  files: {
    uploadButton: 'Upload',
    supportedFileTypesMessage: '({{types}} format only)',
    errors: {
      fileRemovalFailed:
        'An error occurred while trying to remove your file. Please try again or contact our customer support.',
      fileUploadFailed:
        'An error occurred while trying to upload your file. Please try again or contact our customer support.',
    },
  },
  errors: {
    fileTypeNotSupported: 'This file type is not supported. We only support {{types}}',
  },
  contactFormCard: {
    heading: 'Contact us',
    firstAndLastNameLabel: 'First & Last name',
    emailLabel: 'Email',
    phoneNumberLabel: 'Phone number',
    messageLabel: 'Message',
    contactFormHint:
      'The request is free of charge and <a href="{{dataPrivacyLink}}" class="underline">your data</a> is safe with us',
    sendMessageButtonLabel: 'Send message',
    successMessageButtonLabel: 'Success',
  },
  clientPriceRangeCard: {
    expectedPrice: 'Expected price:',
    priceTo: 'to',
    priceUnknown: 'Unknown',
    notSpecified: 'Not specified yet',
    free: 'Free of charge',
    containUnknownsTextClient:
      'There are some unknowns in your criteria which may result in you receiving offers outside of the estimated price range.',
    priceIncalculableHint:
      "Unfortunately we are not able to calculate a price right now. Please continue filling out the form and we'll send a craftsman to you to calculate an exact price.",
  },
  minimumPriceCard: {
    fromPrice: 'from CHF {{fromPrice}}.-',
  },
  priceBreakdown: {
    craftsManServiceCost: 'Craftsman service cost',
    additionalCost: 'Additional costs',
    fee: 'Buildigo fee',
    materialCost: 'Material costs',
    notSpecified: 'Not specified yet',
  },
  slider: {
    forward: 'See more',
    back: 'Back',
  },
  chipsSelectAll: 'All',
  heroSearchbar: {
    placeholder: 'Search...',
  },
  author: {
    from: 'from',
  },
}
