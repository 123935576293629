import {Injectable} from '@angular/core'
import {ApplicationRoute, LOCALIZED_ROUTE_TOKEN} from './application.route'
import {I18nService} from '../i18n/i18n.service'

@Injectable({providedIn: 'root'})
export class PathService {
  constructor(private readonly i18n: I18nService) {}

  localizedPathTo(destination: ApplicationRoute, opts?: Options, language?: string): string {
    const lang = (() => {
      if (language) {
        return language
      }

      return this.i18n.getActiveLang()
    })()

    // replace the locale route param
    let path = destination.path.slice().replace(LOCALIZED_ROUTE_TOKEN, lang)

    // replace potential route params
    if (opts?.routeParams) {
      for (const [key, value] of Object.entries(opts.routeParams)) {
        path = path.replace(`:${key}`, value)
      }
    }

    // add potential query params
    if (opts?.queryParams) {
      const queryParams = Object.entries(opts.queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
      path = `${path}?${queryParams}`
    }

    return path
  }
}

interface Options {
  queryParams?: Record<string, string>
  routeParams?: Record<string, string>
}
