import {Injectable} from '@angular/core'
import defaultLabels from './default.labels'
import {Observable, of} from 'rxjs'

@Injectable({providedIn: 'root'})
export class I18nService {
  private provider: TranslationProvider = {
    selectTranslate: (key: string, params?: Record<string, string>) => {
      return of(this.replaceParams(this.find(key.split('.'), defaultLabels, '') || key, params))
    },
    translate: (key: string, params?: Record<string, string>) => {
      return this.replaceParams(this.find(key.split('.'), defaultLabels, '') || key, params)
    },
    getActiveLang: () => {
      return 'en'
    },
    load(_lang: string): Observable<Labels> {
      return of(defaultLabels)
    },
  }

  override(provider: TranslationProvider) {
    this.provider = provider
  }

  load(lang: string): Observable<Labels> {
    return this.provider.load(lang)
  }

  translate$(key: string, params?: Record<string, string>): Observable<string> {
    return this.provider.selectTranslate(key, params)
  }

  /**
   * Only use this method if you are sure that the translations are fully loaded.
   */
  translate(key: string, params?: Record<string, string | number>): string {
    return this.provider.translate(key, params)
  }

  getActiveLang(): string {
    return this.provider.getActiveLang()
  }

  private find(keys: string[], language: Labels, path: string): string | undefined {
    const key = keys.shift()

    if (typeof key === 'undefined') {
      console.info(
        `Translation key is undefined. This most likely happens if the translation values is not of type "string"`,
      )
      return undefined
    }

    if (key in language) {
      const translation = language[key]
      if (typeof translation === 'string') {
        if (keys.length) {
          console.info(
            `Key "${path}${key}" does not seems to be a final translation value. More nesting expected ${path}${key}(.${keys.join(
              '.',
            )})`,
          )
        }
        return translation
      }
      return this.find(keys, translation, `${path}${key}.`)
    }
    // console.log so we don't get flooded in tests
    console.log(`Translation value to key "${path}${key}" not found`)
    return undefined
  }
  private replaceParams(label: string, params?: Record<string, string>): string {
    if (!params) {
      return label
    }
    return Object.entries(params).reduce((label, [key, value]) => {
      return label.replace(new RegExp(`{{${key}}}`, 'g'), value)
    }, label)
  }
}

export interface Labels {
  [key: string]: string | Labels
}

export interface TranslationProvider {
  selectTranslate: (key: string, params?: Record<string, string>) => Observable<string>
  translate: (key: string, params?: Record<string, string | number>) => string
  getActiveLang(): string
  load(lang: string): Observable<Labels>
}
